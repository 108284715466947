import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FiltersListProps } from "../../../types/components/dropdown/SatelliteDetectionFilters";
import { t } from "i18next";

const FiltersList: React.FC<FiltersListProps> = ({
  filtersList,
  handleToggle,
  selectedFires,
  noPadding,
}) => {
  return (
    <Grid
      item
      xs={6}
      className={`filters-grid ${noPadding ? "no-padding" : ""}`}
    >
      <List className="filters-list">
        {filtersList.map((item) => {
          const labelId = `foco ${item.label}`;

          return (
            <ListItem className="item-list" key={labelId} disablePadding>
              <ListItemButton
                className="item-button"
                role={undefined}
                onClick={() => handleToggle(item.index)}
                dense
              >
                <ListItemIcon sx={{ minWidth: "fit-content" }}>
                  <Checkbox
                    style={{
                      color: item.color,
                    }}
                    edge="start"
                    checked={selectedFires[item.index]}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={t(item.label)}
                  sx={{
                    "& .MuiTypography-root": {
                      fontWeight: 700,
                      color: "#49454F",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default FiltersList;
