import {
	Update,
	HistoryToggleOff,
	SettingsOutlined,
	FeedbackOutlined,
	AddAlertOutlined,
	HelpOutline,
	QuizOutlined,
	//VideoCameraBack
} from "@mui/icons-material";

//import i18n from '../../i18nextConf';

const items = [
	{
		icon: Update,
		label: "tiempo-real-titulo-menu",
	},
	{
		icon: HistoryToggleOff,
		label: "focos-historicos-titulo-menu",
	},
	{
		icon: AddAlertOutlined,
		label: "alertas-titulo-menu",
	},
	/* {
		icon: VideoCameraBack,
		label: "Cámaras",
	}, */
	{
		icon: SettingsOutlined,
		label: "configuracion-titulo-menu",
	},
	{
		icon: FeedbackOutlined,
		label: "comentarios-titulo-menu",
	},
	{
		icon: QuizOutlined,
		label: "titulo-faq",
	},
	{
		icon: HelpOutline,
		label: "instructivo-titulo-menu",
	},
];

const filtrosDeFoco = [
	//{ label: i18n.t('procesado'), color: "#771717", index: 0, value: 30 },
	{ label: "procesado", color: "#771717", index: 0, value: 30 },
	{ label: "saturado", color: "#B71C1C", index: 1, value: 31 },
	{ label: "probabilidad-alta", color: "#F44336", index: 3, value: 33 },
	{ label: 'probabilidad-media', color: "#FF7A00", index: 4, value: 34 },
	{ label: 'probabilidad-baja', color: "#F6BF26", index: 5, value: 35 },
	{ label: 'contaminado-por-nubes', color: "#90A4AE", index: 2, value: 32 },
	{ label: 'rayos', color: "#4264FB", index: 6, value: 101 },
	//{ label: 'deforestation', color: "#4264FB", index: 7, value: null } // value: [20, 50, 90, 100]
];

export { items, filtrosDeFoco };
