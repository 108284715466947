import gql from "graphql-tag";
import { getPublicWildfireByDate, getPublicSRWildfireByDate } from "../../graphql/queries";
import { ApiKeyConfig } from "./utils/ApiKeyConfig";
//import { strike } from "aws-amplify";

async function queryWildfires(minutesBefore, wildfiresDate, wildfiresQueryProperty = 'getPublicWildfireByDate', limit = 100000, token="") {

	const geDate = new Date();
	geDate.setMinutes(geDate.getMinutes() - minutesBefore);
	const geString = geDate.toISOString().slice(0,16);

	let variables = {
		date: wildfiresDate,
		idGtThan: geString,
		limit: limit,
		filter: {conf: {ne:101}}
	}
	
	if (minutesBefore < 30) delete variables["filter"];
	if (token !== "") variables["nextToken"] = token;

	const gqlQuery = {
		'getPublicWildfireByDate': getPublicWildfireByDate,
		'getPublicSRWildfireByDate': getPublicSRWildfireByDate,
	}[wildfiresQueryProperty];

	const { data } = await ApiKeyConfig.query({
		query: gql(gqlQuery),
		variables
	});
	const {items, nextToken} = data[wildfiresQueryProperty];

	return {items, nextToken};
}

export async function fetchWildfires (minutesBefore = 15, source = 'getPublicWildfireByDate', limit = 100000) {
	let from = new Date();
	let to = new Date();
	
	from.setMinutes(from.getMinutes() - minutesBefore);
	let wildfires = [];

	for (let i = from.getHours(); i !== (to.getHours() + 1) % 24; i = (i + 1) % 24) {
		
		const dateTemp = new Date();
		dateTemp.setHours(i);
		const str = dateTemp.toISOString().slice(0,13);

		let jsonQuery = await queryWildfires(minutesBefore, str, source, limit);
		wildfires = wildfires.concat(jsonQuery["items"]);

		//let counter = 1;
		while (jsonQuery["nextToken"]) {
			//if (counter == 5) { break; }
			jsonQuery = await queryWildfires(minutesBefore, str, source, limit, jsonQuery["nextToken"]);
			wildfires = wildfires.concat(jsonQuery["items"]);
			//counter++;
		}
	}
	return wildfires;
}