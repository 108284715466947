import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { actions as sidebarActions } from "../../../redux/slices/sidebarSlice";
import IAIcon from "../../../assets/icons/ia_detection_icon.png";
import feedback_modal from "../../../assets/images/feedback_modal.png";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "../../StyledComponents/Button";


export function IADetectionIntro() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
	const history = useHistory();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const isFirstSlide = currentSlide === 0;

  const slides = [
    {
      title: t("ia-slides-1-title"),
      description: t("ia-slides-1-description")
    },
    {
      title: t("ia-slides-2-title"),
      description: t("ia-slides-2-description")
    },
  ];

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    dispatch(sidebarActions.setMode('ia'));
  }, []);

  const openDialog = () => history.push("/alertas/crear");

  return (
    <Box className="ia-detection">
      <Box className="header">
        <img src={IAIcon} alt="" width={30} height={35} />
        <Typography variant="h6">
          {t("ia-title")}
        </Typography>
      </Box>
      <Box className="slide-content">
        <IconButton
          className={`prev-slide-button ${isFirstSlide ? "non-visible" : ""}`}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Box className="slide-content-container">
          <Box className="image-container">
            <img src={feedback_modal} alt="" width={150} />
          </Box>
          <Box className="text-container">
            <Typography className="slide-title" variant="body1">
              {slides[currentSlide].title}
            </Typography>
            <Typography
              className="slide-description"
              variant="body2"
              color="GrayText"
              dangerouslySetInnerHTML={{
                __html: slides[currentSlide].description,
              }}
            ></Typography>
            <Button variant="contained" onClick={openDialog}>
              {t("boton-crear-zona-prueba")}
            </Button>
          </Box>
        </Box>
        <IconButton
          className={`next-slide-button ${!isFirstSlide ? "non-visible" : ""}`}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <Box className="dots-container">
        {slides.map((_, index) => (
          <Box
            key={index}
            className={`dot ${currentSlide === index ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </Box>
    </Box>
  );
}
