export function areSameDate(lhs: Date, rhs: Date): boolean {
  return (
		lhs.getFullYear() === rhs.getFullYear() &&
		lhs.getMonth() === rhs.getMonth() &&
		lhs.getDate() === rhs.getDate()
	);
}

export function formatDateDDMMYYYY(date: Date): string {
  return date.toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
}

export function formatDateHHMM(date: Date): string {
  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
}