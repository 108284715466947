import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useMap } from '@vis.gl/react-google-maps';

type CustomGeolocationControlProps = {
  changeLocation: (location: google.maps.LatLngLiteral) => void;
};

export const CustomGeolocationControl = ({
  changeLocation
}: CustomGeolocationControlProps) => {
  
  const map = useMap();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) console.log(error);
  }, [error]);

  const travelToLocation = (lat: number, lng: number) => {
    const targetPosition = { lat, lng };
    map?.panTo(targetPosition);
    map?.setZoom(13);
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          travelToLocation(latitude, longitude);
          changeLocation({lat: latitude, lng: longitude})
          setError(null);
        },
        (err) => {
          setError('Unable to retrieve your location');
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
  };

  return (
    <Button
      sx={{
        minWidth: '20px',
        minHeight: '20px',
        padding: 0,
        color: '#000',
      }}
      onClick={handleGeolocation}
    >
      <MyLocationIcon />
    </Button>
  );
};
