import { Button, Box } from '@mui/material';
import Straighten from '@mui/icons-material/Straighten';
import { useDrawingManager } from '../use-drawing-manager';

export const CustomMeasureControl = () => {
  const {drawingManager, toggleDrawingMode} = useDrawingManager();

  return (
    <Button
      onClick={toggleDrawingMode}
      sx={{
        minWidth: '20px',
        minHeight: '20px',
        padding: 0,
        color: '#000',
      }}
    >
        <Straighten />
    </Button>
  );
};
