import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { formatDateDDMMYYYY, formatDateHHMM } from './DateUtils';

interface ExtraTextProps {
	layerName: string;
	datePath: string;
	align?: "inherit" | "left" | "center" | "right" | "justify";
}

export default function ExtraText({layerName, datePath, align = "left"}: ExtraTextProps) {
	const { t } = useTranslation();
	const now = new Date();
	now.setMinutes(0);

	let prefix: string | undefined;

	if (layerName === "windy-wind") {
		prefix = t("vientos-de");
	} else if (layerName === "windy-temp") {
		prefix = t("temperatura-de");
	} else if (layerName === "windy-pressure") {
		prefix = t("presion-de");
	} else if (layerName === "windy-rh") {
		prefix = t("humedad-de");
	}

	if (prefix && datePath) {
		const date = new Date(
			parseInt(datePath.substring(0, 4), 10),
			parseInt(datePath.substring(4, 6), 10) - 1,
			parseInt(datePath.substring(6, 8), 10),
			parseInt(datePath.substring(8, 10), 10) - 3
		);
		const extraText: string = `${prefix} ${formatDateDDMMYYYY(date)} ${t("a-las")} ${formatDateHHMM(date)}`;
		return (
			<Typography component="span" fontSize=".9rem" align={align}>{extraText}</Typography>
		);
	}

	return null;
}
