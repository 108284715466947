/* eslint-disable no-extend-native */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Box, Typography } from "@mui/material";
import { areSameDate, formatDateDDMMYYYY, formatDateHHMM } from '../../utils/DateUtils';
import ExtraText from '../../utils/ExtraText';
import { selectDatePath, selectLayer } from '../../../redux/slices/mapSlice';

interface DatesOverlayProps {
	startDate: Date;
	endDate: Date;
	screen: number;
}

export default function DatesOverlay({ startDate, endDate, screen }: DatesOverlayProps) {
	const { t } = useTranslation();
	const layer = useSelector(selectLayer);
	const datePath = useSelector(selectDatePath);

	return (
		<Box
			sx={{
				position: "fixed",
				right: "70px",
				top: "20px",
				backgroundColor: "white",
				borderRadius: "5px",
				padding: "5px 10px",
				width: "fit-content",
				display: "flex",
				flexDirection: "column"
			}}
		>
			{areSameDate(startDate, endDate) ? (
				<Typography component="span" fontSize=".9rem">
					{t('focos-del.focos-del')}{" "}
					{formatDateDDMMYYYY(startDate)}{" "}
					{t('focos-del.entre-las')}{" "}
					{formatDateHHMM(startDate)}{" "}
					{t('focos-del.y-las')}{" "}
					{formatDateHHMM(endDate)}
				</Typography>
			) : (
				<Typography component="span" fontSize=".9rem">
					{screen === 1 ? t('focos-entre.rayos-entre') : t('focos-entre.focos-entre')}{" "}
					{formatDateDDMMYYYY(startDate)}{" "}
					{t('a-las')}{" "}
					{formatDateHHMM(startDate)}{" "}
					{t('focos-entre.y-el')}{" "}
					{formatDateDDMMYYYY(endDate)}{" "}
					{t('a-las')}{" "}
					{formatDateHHMM(endDate)}
				</Typography>
			)}
			<ExtraText layerName={layer} datePath={datePath} />
		</Box>
	);
}

