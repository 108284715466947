import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filtrosDeFoco } from "../../utils/Utils";
import { actions } from "../../../redux/slices/sidebarSlice";
import FiltersList from "./FiltersList";

export default function SatelliteFilters() {
  const dispatch = useDispatch();
  const selectedFires = useSelector(
    (state: any) => state.sidebar.selectedFires
  );

  function handleToggle(value: number) {
    let temp = [...selectedFires];
    temp[value] = !temp[value];
    dispatch(actions.selectFireType(temp));
  }

  const leftItems = filtrosDeFoco.slice(0, 3);
  const rightItems = filtrosDeFoco.slice(3, 6);

  return (
    <Grid className="filters-grid-container" container spacing={2}>
      <FiltersList
        filtersList={leftItems}
        handleToggle={handleToggle}
        selectedFires={selectedFires}
      />
      <FiltersList
        filtersList={rightItems}
        handleToggle={handleToggle}
        selectedFires={selectedFires}
        noPadding
      />
    </Grid>
  );
}
