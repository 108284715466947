/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPolygon = /* GraphQL */ `mutation CreatePolygon($input: CreatePolygonInput!) {
  createPolygon(input: $input) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePolygonMutationVariables,
  APITypes.CreatePolygonMutation
>;
export const createPolygonPipelineResolverSample = /* GraphQL */ `mutation CreatePolygonPipelineResolverSample($input: CreatePolygonInput!) {
  createPolygonPipelineResolverSample(input: $input) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePolygonPipelineResolverSampleMutationVariables,
  APITypes.CreatePolygonPipelineResolverSampleMutation
>;
export const updatePolygon = /* GraphQL */ `mutation UpdatePolygon($input: UpdatePolygonInput!) {
  updatePolygon(input: $input) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePolygonMutationVariables,
  APITypes.UpdatePolygonMutation
>;
export const updatePolygonPipelineResolverSample = /* GraphQL */ `mutation UpdatePolygonPipelineResolverSample($input: UpdatePolygonInput!) {
  updatePolygonPipelineResolverSample(input: $input) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePolygonPipelineResolverSampleMutationVariables,
  APITypes.UpdatePolygonPipelineResolverSampleMutation
>;
export const deletePolygon = /* GraphQL */ `mutation DeletePolygon($input: DeletePolygonInput!) {
  deletePolygon(input: $input) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePolygonMutationVariables,
  APITypes.DeletePolygonMutation
>;
export const updateCamera = /* GraphQL */ `mutation UpdateCamera($input: UpdateCameraInput!) {
  updateCamera(input: $input) {
    name
    cameraId
    cameraUrl
    cgiPort
    ip
    user
    password
    x
    y
    elevation
    sensorHeight
    sensorWidth
    movementType
    lastDetectionDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCameraMutationVariables,
  APITypes.UpdateCameraMutation
>;
export const createNotificationEndpoint = /* GraphQL */ `mutation CreateNotificationEndpoint($input: CreateNotificationEndpointInput!) {
  createNotificationEndpoint(input: $input) {
    endpointId
    endpointName
    endpoint
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationEndpointMutationVariables,
  APITypes.CreateNotificationEndpointMutation
>;
export const updateNotificationEndpoint = /* GraphQL */ `mutation UpdateNotificationEndpoint($input: UpdateNotificationEndpointInput!) {
  updateNotificationEndpoint(input: $input) {
    endpointId
    endpointName
    endpoint
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationEndpointMutationVariables,
  APITypes.UpdateNotificationEndpointMutation
>;
export const deleteNotificationEndpoint = /* GraphQL */ `mutation DeleteNotificationEndpoint($input: DeleteNotificationEndpointInput!) {
  deleteNotificationEndpoint(input: $input) {
    endpointId
    endpointName
    endpoint
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationEndpointMutationVariables,
  APITypes.DeleteNotificationEndpointMutation
>;
export const updateUserConfig = /* GraphQL */ `mutation UpdateUserConfig($input: UpdateUserConfigInput) {
  updateUserConfig(input: $input) {
    name
    email
    country
    phone
    organizationName
    organizationType
    freeTrial
    language
    notifyByEmail
    notifyByPush
    notifyByPhone
    userType
    userTypeTill
    defaultLayer
    notifyMinuteGap
    typeOfAlert
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConfigMutationVariables,
  APITypes.UpdateUserConfigMutation
>;
export const startFreeTrial = /* GraphQL */ `mutation StartFreeTrial($email: String!) {
  startFreeTrial(email: $email) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartFreeTrialMutationVariables,
  APITypes.StartFreeTrialMutation
>;
export const redeemPromoCode = /* GraphQL */ `mutation RedeemPromoCode($promoCode: String!) {
  redeemPromoCode(promoCode: $promoCode) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RedeemPromoCodeMutationVariables,
  APITypes.RedeemPromoCodeMutation
>;
export const createZoneKML = /* GraphQL */ `mutation CreateZoneKML($kml: String!) {
  createZoneKML(kml: $kml) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateZoneKMLMutationVariables,
  APITypes.CreateZoneKMLMutation
>;
export const updateCameraEvent = /* GraphQL */ `mutation UpdateCameraEvent($input: UpdateCameraEventInput) {
  updateCameraEvent(input: $input) {
    date_id
    bbox
    classNumber
    confidence
    confirmWildfire
    hasBeenDetectedByHuman
    x
    y
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCameraEventMutationVariables,
  APITypes.UpdateCameraEventMutation
>;
export const createWildfiresReview = /* GraphQL */ `mutation CreateWildfiresReview($input: CreateWildfiresReviewInput!) {
  createWildfiresReview(input: $input) {
    user_id
    wildfire_id
    wildfire_date
    result
    origin
    review_date
    user_description
    source
    cathegory
    x
    y
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWildfiresReviewMutationVariables,
  APITypes.CreateWildfiresReviewMutation
>;
