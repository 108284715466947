import React, { useState, useRef } from "react";
import { GoogleLayer } from 'react-leaflet-google-v2';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18nextConf';
import L from "leaflet";
import {
	MapContainer,
	FeatureGroup,
	Polygon,
	LayersControl,
} from "react-leaflet";
import swal from "sweetalert";
import getCenter from "../utils/getCenter";
import { useSelector } from "react-redux";
import { EditControl } from "react-leaflet-draw";
import drawLocales from "leaflet-draw-locales";

//import i18Next from "../../i18nextConf"
delete L.Icon.Default.prototype._getIconUrl;

// Automatically defines Leaflet.draw to the specified language
//drawLocales("es");

function setLanguage() {
	const language = i18n.language.substring(0, 2)
	drawLocales(language);
}

L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	iconUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	shadowUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});
export default function LeafletMap(props) {
	const { BaseLayer } = LayersControl;
	const { t } = useTranslation();

	const [center] = useState({ lat: -33, lng: -64.5 });
	//const ZOOM_LEVEL = 4;
	const layerRef = useRef();

	const [map, setMap] = useState();
	const [coords, setCoords] = React.useState();
	const selectedLayer = useSelector((state) => state.map.layerLeaflet);

	const _created = (e) => {
		const { layerType, layer } = e;
		const coords = layer.editing.latlngs[0];
		if (layerType === "polygon") props.setFeatureCoords(coords);
		//layer.editing._poly.options.style.fillColor = '#0000FF';
		if (coords[0].length > 15) {
			swal(
				t("swal-no-crear-poligono-vertices-titulo"),
				t("swal-no-crear-poligono-vertices-texto"),
				"error"
			).then(() => {
				props.setFeatureCoords();
			});
		}
		props.onCreate();
	};

	const _edited = (e) => {
		const changedPolys = Object.values(e.layers._layers).map((layer) => {
			const latlngs = layer._latlngs[0].map((latlng) => {
				return [latlng.lat, latlng.lng];
			});
			return { id: layer.options.id, latlngs: latlngs };
		});

		if (!changedPolys) return;

		if (changedPolys[0].latlngs.length > 15) {
			swal(
				t("swal-no-crear-poligono-vertices-titulo"),
				t("swal-no-crear-poligono-vertices-texto"),
				"error"
			).then(() => {
				if (props.edit && props.restoreDraw) props.restoreDraw();
				else props.setFeatureCoords();
			});
		} else props.setFeatureCoords(changedPolys[0].latlngs);
		props.onEdit();
	};

	React.useEffect(() => {
		if (props.featureCoords) {
			const coords = props.featureCoords;
			setCoords(coords);
		}
		//eslint-disable-next-line
	}, [props.featureCoords]);

	React.useEffect(() => {
		if (!layerRef.current || !map) return;

		map.fitBounds(layerRef.current.getBounds(), { padding: [10, 10] });
		//eslint-disable-next-line
	}, [coords, map]);

	React.useEffect(() => {
		const leafletStyles = document.createElement('link');
		const leafletDrawStyles = document.createElement('link');

		leafletStyles.rel = 'stylesheet';
		leafletStyles.href = '/leaflet/leaflet.css';

		leafletDrawStyles.rel = 'stylesheet';
		leafletDrawStyles.href = '/leaflet/leaflet.draw.css';

		document.head.appendChild(leafletStyles);
		document.head.appendChild(leafletDrawStyles);

		return () => {
			document.head.removeChild(leafletStyles);
			document.head.removeChild(leafletDrawStyles);
		};
	}, []);

	const _onMounted = (drawControl) => {
		if (props.setDrawControl) props.setDrawControl(drawControl);
		setLanguage();
	};

	React.useEffect(() => {
		setLanguage();
		//eslint-disable-next-line
	}, [i18n.language])


	return (
		<>
			{(props.edit && props.featureCoords) || !props.edit ? (
				<MapContainer
					whenCreated={setMap}
					style={{ zIndex: 0, height: "100%" }}
					//className="mapa-leaflet"
					center={props.edit ? getCenter(props.featureCoords) : center}
					zoom={props.edit ? 8 : 4}
					{...props.mapProps}
				>
					<FeatureGroup>
						{coords && (
							<Polygon
								ref={layerRef}
								positions={coords}
								type="draw:edit"
								edit={true}
							//fill="DAE1FF"
							/>
						)}
						{props.edit ? (
							<EditControl
								className="editcontrol"
								position="topright"
								onCreated={_created}
								onEdited={_edited}
								onMounted={_onMounted}
								// onDeleted={_deleted}

								draw={{
									rectangle: false,
									polygon: false,
								}}
							/>
						) : (
							<EditControl
								className="editcontrol"
								position="topright"
								onCreated={_created}
								onEdited={_edited}
								onMounted={_onMounted}
								// onDeleted={_deleted}

								draw={{
									rectangle: false,
								}}
							/>
						)}
					</FeatureGroup>
					<LayersControl>
						<BaseLayer checked={selectedLayer === 0} name="Capa Político">
							<GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_TOKEN} maptype='TERRAIN' />
						</BaseLayer>
						<BaseLayer checked={selectedLayer === 1} name="Capa Satélital">
							<GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_TOKEN} maptype='SATELLITE' />
						</BaseLayer>
					</LayersControl>
				</MapContainer>
			) : (
				<React.Fragment />
			)}
		</>
	);
}
