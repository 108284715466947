import { ScatterplotLayer, } from '@deck.gl/layers';
import { PickingInfo } from '@deck.gl/core';
import { MjolnirEvent } from 'mjolnir.js';
import { MapWildfire } from '../../../../redux/slices/types/wildfireTypes';

const hexToRgb = (hex: string, a: number = 256): [number, number, number, number] => {
  hex = hex.replace("#", "");

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b, a];
};

export function wildfireScatteredPlot(wildfires: MapWildfire[], wildfireType: 'user' | 'public', onClickWildfire: (id: string) => void): ScatterplotLayer {

  const onClick = (pickingInfo: PickingInfo<MapWildfire>, event: MjolnirEvent) => {
    onClickWildfire(pickingInfo.object?.id || "");
  }

  const layer: ScatterplotLayer<MapWildfire> = new ScatterplotLayer<MapWildfire>({
    /*
    parameters: {
      blendColorOperation: 'add',
      blendColorSrcFactor: 'dst-color',
      blendColorDstFactor: 'one-minus-src-alpha',
      blendAlphaOperation: 'add',
      blendAlphaSrcFactor: 'one',
      blendAlphaDstFactor: 'one-minus-src-alpha'
    }, */
    id: 'WildfireScatteredPlotLayer',
    data: wildfires,
    stroked: true,
    pickable: true,
    radiusMinPixels: 6,
    radiusUnits: 'meters',
    //maxZoom: 5,
    getPosition: (d: MapWildfire) => [d.x, d.y],
    getRadius: (d: MapWildfire) => {
      if (d.satellite.includes('goes') && !d.satellite.includes('sr')) return 2000 / 2;
      else if (d.satellite.includes('modis')) return 1000 / 2;
      else return 375 / 2;
    },
    getLineWidth: 10,
    getLineColor: (d: MapWildfire) => hexToRgb(d.color, 256),
    getFillColor: (d: MapWildfire) => hexToRgb(d.color, 200),
    onClick: onClick
  });

  return layer;
}