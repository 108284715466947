import React from 'react';
import { MapProps } from './MapTypes';

import Box from '@mui/material/Box';
import SwitchLayerMap from './SwitchLayerMap';
import WindyMap from './leafletComponents/WindyMap';
import DetectionDropdown from '../DropDown/detection/DetectionDropdown';

import "react-leaflet-fullscreen/styles.css";
import LogoSOF from "../../assets/SOF_H_white_.svg";
import GoogleMaps from './googleMapsComponents/BaseMapGoogleMaps';


function LogosContainer({ withWindyLogo }: { withWindyLogo: boolean }) {
  return (
    <div id="windy-sof-logo-container">
      <a href="https://satellitesonfire.com">
        <img src={LogoSOF} width="250" alt="Logo Satellites On Fire" />
      </a>
      {withWindyLogo
        ? <a href="https://www.windy.com/">
          <img
            src="https://www.windy.com/img/logo201802/logo-full-windycom-white.svg"
            width="150"
            alt="Windy.com"
            id="windy-logo-img"
          />
        </a> : <></>}
    </div>
  );
}

function MapControls(props: { layer: string }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="absolute"
      bottom="20px"
      right="20px"
      gap="20px"
      alignItems="flex-end"
      sx={{ "& *": { zIndex: 10 } }}
      marginBottom={ props.layer.startsWith("windy") ? '40px' : '' }
    >
      <DetectionDropdown />
      <SwitchLayerMap />
    </Box>
  )
}

export function BaseMap(props: MapProps) {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      {
        props.layer.startsWith("windy") ?
          <WindyMap {...props} /> :
          <GoogleMaps {...props} />
      }
      <LogosContainer withWindyLogo={props.layer.startsWith("windy")} />
      <MapControls layer={props.layer} />
    </div>
  );
};

export default BaseMap;