import { ParsedPolygon } from "../../MapTypes";
import { PolygonLayer } from '@deck.gl/layers';
import { PickingInfo } from '@deck.gl/core';
import { MjolnirEvent } from 'mjolnir.js';


export function wildfirePolygon(polygon: ParsedPolygon, onClickPolygon: (id: string) => void): PolygonLayer<ParsedPolygon> {

  const onClick = (pickingInfo: PickingInfo<ParsedPolygon>, event: MjolnirEvent) => {
    onClickPolygon(pickingInfo.object?.id || "");
  }

  const layer: PolygonLayer<ParsedPolygon> = new PolygonLayer<ParsedPolygon>({
    id: 'WildfirePolygonLayer',
    data: [polygon],
    stroked: true,
    filled: false,
    pickable: true,
    getLineColor: [255, 255, 255],
    lineWidthUnits: 'pixels',
    getLineWidth: 3,
    getPolygon: (d: ParsedPolygon) => d.coords?.map((coord) => [coord[1], coord[0]]) || [],
    onClick
  });

  return layer;
}