import { useMap } from '@vis.gl/react-google-maps';
import { Button, Box } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useState } from 'react';

export const CustomFullScreenControl = () => {
    const map = useMap();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        if (map) {
          const mapElement = map.getDiv();
          if (!document.fullscreenElement) {
            // Request fullscreen for the map element
            mapElement.requestFullscreen();
            setIsFullscreen(true);
          } else {
            // Exit fullscreen mode
            document.exitFullscreen();
            setIsFullscreen(false);
          }
        }
    };

    return (
        <Button
            onClick={toggleFullscreen}
            sx={{
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                color: '#000',
            }}
        >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Button>
    );
};
