import { getPublicWildfireByDate, getPublicSRWildfireByDate } from "../../graphql/queries";
import { ApiKeyConfig } from './utils/ApiKeyConfig';
import gql from 'graphql-tag';

async function queryWildfiresByDate (UTCDate, wildfiresQueryProperty = 'getPublicWildfireByDate', limit = 100000, token = "") {
	let variables = {
		date: UTCDate,
		limit: limit,
		filter: { conf: { ne: 101 } },
	}

	if (token !== "") variables["nextToken"] = token;

	const gqlQuery = {
		'getPublicWildfireByDate': getPublicWildfireByDate,
		'getPublicSRWildfireByDate': getPublicSRWildfireByDate,
	}[wildfiresQueryProperty];

	const { data } = await ApiKeyConfig.query({
		query: gql(gqlQuery),
		variables,
		fetchPolicy: "no-cache",
	});
	const { items, nextToken } = data[wildfiresQueryProperty];

	return { items, nextToken };
}

export async function getByDate(date /*:Date object*/, source = 'getPublicWildfireByDate', limit = 100000) {
	const strQuery = date.toISOString().slice(0, 13); // Convierte a UTC, y extrae el substring que nos interesa
	let wildfires = [];

	let jsonQuery = await queryWildfiresByDate(strQuery, source, limit);
	wildfires = wildfires.concat(jsonQuery["items"]);

	while (jsonQuery["nextToken"]) {
		jsonQuery = await queryWildfiresByDate(strQuery, source, limit, jsonQuery["nextToken"]);
		wildfires = wildfires.concat(jsonQuery["items"]);
	}

	return wildfires;
}

export default getByDate;