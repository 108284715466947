import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DetectionSwitcherProps } from "../../../types/components/dropdown/SatelliteDetectionFilters";
import { LocalFireDepartment } from "@mui/icons-material";
import iaIcon from "../../../assets/icons/ia_detection_icon.png";

const DetectionSwitcher: React.FC<DetectionSwitcherProps> = ({
  title,
  description,
  selected,
  onSelect,
  detectionType,
}) => {
  const { t } = useTranslation();
  const isIA = detectionType === "ia";

  return (
    <Box
      className={`switcher ${selected ? "selected" : ""} ${isIA ? "isIA" : ""}`}
      onClick={onSelect}
      sx={{ cursor: "pointer" }}
    >
      <Box className="image-container">
        {isIA ? (
          <img src={iaIcon} width={50} height={55} alt="" />
        ) : (
          <LocalFireDepartment
            sx={{ width: "50px", height: "50px", color: "#90A4AE" }}
          />
        )}
      </Box>
      <Box>
        <Box display="flex" position="relative">
          <Typography variant="subtitle2" className="switcher-title">
            {title}
          </Typography>
          {isIA && (
            <Typography variant="caption" className="new-tag">
              {t("nuevo")}
            </Typography>
          )}
        </Box>
        <Typography color={"CaptionText"} className="switcher-description">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default DetectionSwitcher;
