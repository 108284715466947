import { useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

export function CenterController(props: { center: number[] }) {
  const map = useMap();

  useEffect(() => {
    const center = props.center;
    if (center.length > 2 && center[0] && center[1]) {
      map?.panTo({ lat: center[0], lng: center[1] });
      map?.setZoom(center[2] + 2);
    }
  }, [props.center, map]);

  return null;
}

export default CenterController;