/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import userAccess from "./api/user/userAccess.json";
import ForgotPassword from "./screens/LoginScreens/forgotpassword";
import Form from "./screens/LoginScreens/form";
import Login from "./screens/LoginScreens/login";
import Register from "./screens/LoginScreens/register";
import VerifyEmail from "./screens/LoginScreens/verifyemail";
import VerifyPassword from "./screens/LoginScreens/verifypassword";
import NotFound from "./screens/notFound404";
import UserVerifiedPath from "./screens/userVerification";
import "./scss/app.scss";

import DialogInfo from "./components/modals/InfoDeteccion";
import Modal from "./components/utils/Modal";

import { Redirect, Route, Switch } from "react-router-dom";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import IAWarningMessage from "./components/mapa/IAWarningMessage";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import { selectMobile, actions as sidebarActions } from "./redux/slices/sidebarSlice";
import { actions as userActions } from "./redux/slices/userSlice";
import { actions as wildfireActions } from "./redux/slices/wildfireSlice";


function App() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const authorized = useSelector((state) => state.user?.userData?.userData?.userType);
	const loggedIn = useSelector((state) => state.user?.isLoggedIn);
	const sub = useSelector((state) => state.user?.userData?.sub);
	const userTypeTill = useSelector(
		(state) => state.user?.userData?.userData?.userTypeTill
	);
	const mobile = useSelector(selectMobile);

	React.useEffect(() => {
		ReactGA.pageview(history.location.pathname);
		history.listen((location) => {
			if (location.pathname.includes("/zona"))
				document.title = `${t('alertas')} | Satellites On Fire`;
			else {
				document.title = "Satellites On Fire";
			}
			ReactGA.pageview(location.pathname);
		});
		dispatch(userActions.retrieveUserData());
		dispatch(wildfireActions.retrieveUserReviews());
		dispatch(sidebarActions.setMobile());

		document.querySelector("#root").style.setProperty("height", `${window.visualViewport.height}px`);
		document.querySelector("#root").style.setProperty("--height", `${window.visualViewport.height}px`);
		window.addEventListener("resize", () => {
			document.querySelector("#root").style.setProperty("height", `${window.visualViewport.height}px`);
			document.querySelector("#root").style.setProperty("--height", `${window.visualViewport.height}px`);
			window.mapObj?.resize();
			dispatch(sidebarActions.setMobile());
		});
		//eslint-disable-next-line
	}, []);
	React.useEffect(() => {
		if (loggedIn) {
			if (userAccess.alerts.includes(authorized)) {
				ReactGA.event({ category: authorized, action: sub });
				calcDaysOfAlerts(userTypeTill);
			} else ReactGA.event({ category: "Free", action: sub });
		} else ReactGA.event({ category: "No logeado", action: "No está logueado" });
	}, [loggedIn]);

	function calcDaysOfAlerts(finishDate) {
		const date1 = new Date();
		const date2 = new Date(finishDate);
		let timeInMilisec = date2.getTime() - date1.getTime();
		let daysBetweenDates = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
		dispatch(userActions.setDaysOfAlerts(daysBetweenDates));
	}

	const theme = createTheme({
		palette: {
			primary: {
				main: "#3E5AA9",
				light: "#DAE1FF",
			},
			hovered: {
				light: "#b5c2f9",
			},
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					"#root": {
						overflow: "hidden",
						overflowY: "auto",
						justifyContent: "space-between",
						display: mobile ? "flex" : undefined,
						flexDirection: mobile ? "column" : undefined,
						height: mobile ? "100vh" : undefined,
					},
					"::-webkit-scrollbar": !mobile
						? {
							backgroundColor: "#f8f9fa",
							width: "16px",
							zIndex: "50",
						}
						: undefined,
					"::-webkit-scrollbar-track": !mobile
						? {
							backgroundColor: "#f8f9fa",
						}
						: undefined,
					"::-webkit-scrollbar-thumb": !mobile
						? {
							zIndex: "50",
							backgroundColor: "#babac0",
							borderRadius: "16px",
							border: "4px solid #f8f9fa",
						}
						: undefined,
				},
			},
		},
	});
	
	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<IAWarningMessage />
				<ServiceWorkerWrapper />
				<DialogInfo />
				<Modal />
				<Switch>
					<Redirect from="/zonas" to="/alertas" />
					<Route exact path="/login" component={Login} />
					<Route exact path="/verifyemail/:email/:code" component={VerifyEmail} />
					<Route exact path="/forgot" component={ForgotPassword} />
					<Route exact path="/verifypassword" component={VerifyPassword} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/form" component={Form} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy}/>
					<Route path="/" component={UserVerifiedPath} />
					<Route path="*" component={NotFound} />
				</Switch>
			</ThemeProvider>
		</>
	);
}

export default App;
