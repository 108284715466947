import React, {useEffect} from 'react';
import { Snackbar, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../redux/slices/userSlice';
import { useHistory } from 'react-router-dom';
import { selectMode } from '../../redux/slices/sidebarSlice';


const IAWarningMessage = () => {
  const [showIAMessage, setShowIAMessage] = React.useState(true);
  const {t} = useTranslation();
  const loggedIn = useSelector(selectLoggedIn);
  const filtersMode = useSelector(selectMode);
	const history = useHistory();

  const content = (
    <Box sx={{ whiteSpace: "multiline" }}>
        <Typography variant="body1" color="#FFFFFF" fontSize="1rem">
        {t("ia-body-titulo")}
        </Typography>
        <Typography variant="body2" color="#FFFFFF" fontSize="1rem">
        {loggedIn ? t("ia-body-usuario") : t("ia-body-visitante")}
        </Typography>
    </Box>
  );

  const handleClick = () => {
    if (loggedIn) {
      history.push("/alertas/crear");
    } else {
      history.push("/register")
    }
  }

  if (filtersMode !== 'ia') return null;

  return (
    <Snackbar
      open={showIAMessage}
      message={content}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={handleClick}
        >
          {t("comenzar-ia")}
        </Button>
      }
    />
  );
}


export default IAWarningMessage;