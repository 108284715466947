import React from 'react';
import { useHistory } from "react-router-dom";
import { BaseMap as Map } from "../components/mapa/BaseMap";
import { ParsedPolygon } from "../components/mapa/leafletComponents/WildfirePolygon";
import { parsePublicWildfires, parseUserWildfires } from "../components/mapa/leafletUtils/WildfiresParser";
import SnackbarNoAlerts from '../components/modals/SnackbarNoAlerts';
import Backdrop from "../components/utils/BackdropComponents";
import Container from "../components/utils/Container";
import { Camera } from "../graphql/API";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCenter, selectCoordsMarker, selectPolygon, actions } from "../redux/slices/mapSlice";
import { selectPolygons } from "../redux/slices/polygonsSlice";
import { selectStatus } from "../redux/slices/wildfireSlice";
import { typeOfSelectedFires } from "../redux/slices/sidebarSlice";
import { MapWildfire } from "../redux/slices/types/wildfireTypes";
import "../scss/map.scss";
import { filterWildfires } from "../utils/fireFocusFilter";

export default function Mapa() {

	const history = useHistory();
	const dispatch = useAppDispatch();

	// Map
	const layer = useAppSelector((state) => state.map.layerMapbox);
	
	// Wildfires
	const wildfires = useAppSelector((state) => state.wildfires.wildfires);
	
	// Filters
	const showRays = useAppSelector((state) => state.sidebar.showRays);
	const selectedFires = useAppSelector(typeOfSelectedFires);

	// Map
	//const mobile = useAppSelector(selectMobile);
	const coordsMarker = useAppSelector(selectCoordsMarker);
	const polygon = useAppSelector(selectPolygon);
	const polygons = useAppSelector(selectPolygons);
	const center = useAppSelector(selectCenter);
	const status = useAppSelector(selectStatus);
	const cameras = useAppSelector((state) => state.camera.cameras);

	// Type
	let wildfireType: 'user' | 'public' = 'public';

	let wildfiresParam: MapWildfire[] = [];
	let camerasParam: Camera[] = [];
	let polygonParam: ParsedPolygon = {id: "", coords: []};

	const show = {
		wildfires: !history.location.pathname.includes("alertas/zona") && !history.location.pathname.includes("/camaras"),
		alerts: history.location.pathname.includes("/alertas/zona"),
		rays: showRays,
		cameras: history.location.pathname.includes("/camaras")
	};

	if (show.alerts) {
		if (polygon.length !== 0) {
			wildfiresParam = filterWildfires(parseUserWildfires(polygon[0]), selectedFires);
			wildfireType = 'user'
			polygonParam = polygon[1];
		}
	} else if (show.wildfires) { //nuevo
		wildfiresParam = filterWildfires(parsePublicWildfires(wildfires), selectedFires);
		wildfireType = 'public'
		if (!show.rays)
			wildfiresParam = wildfiresParam.filter((element) => element.cathegoryNum !== 101);
	} if (show.cameras) 
		camerasParam = cameras;
	else camerasParam = []

	function onClickPoly(id: string){
		history.push(`alertas/zona?id=${id}`);
	}

	function onClickCamera(cameraID: string) {
		history.push("/camaras/cam?id=" + cameraID)
	}

	function onClickWildfire(id: string) {
		dispatch(actions.setWildfireClicked(id));
	}

	return (
		<>
			<Backdrop open={status === "loading"} />
			<SnackbarNoAlerts />
			<Container
				map={
					<Map
						layer={layer}
						center={center}

						defaultCenter={[-20.5,-60.5]}
						defaultZoom={4}

						wildfires={wildfiresParam}
						polygons={polygons}
						polygon={polygonParam}
						cameras={camerasParam}
						coordsMarker={coordsMarker}
						wildfireType={wildfireType}

						onClickPoly={onClickPoly}
						onClickCamera={onClickCamera}
						onClickWildfire={onClickWildfire}
					/>
				}
			/>
		</>
	);
}