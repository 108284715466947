// Escala de confiabilidad
export const IA = "IA";
export const RAYS = "RAYS";
export const DEFORESTATION = "DEFORESTATION";

export const PROCESSED = "Procesado";
export const SATURATED = "Saturado";
export const HIGH_PROBABILITY = "Probabilidad alta";
export const MEDIUM_PROBABILITY = "Probabilidad Media";
export const LOW_PROBABILITY = "Probabilidad Baja";
export const CLOUD_POLLUTED = "Contaminado por nubes";

//Tipos de satelites
export const MODIS = "MODIS";