import { useState } from "react";
import "../../../scss/dropdown.scss";
import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { selectMobile } from "../../../redux/slices/sidebarSlice";
import SatelliteDetectionFilters from "./SatelliteDetectionFilters";
import DetectionSwitcher from "./DetectionSwitcher";
import { IADetectionIntro } from "./IADetectionIntro";
import { Layers, LocalFireDepartment } from "@mui/icons-material";

const DetectionDropdown = () => {
  const [open, setOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const handleToggleClick = () => {
    setOpen(!open);
  }

  const hideMenu = () => {
    setOpen(false);
  }

  return (
    <Box 
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        /*
        padding: open ? "10px" : undefined,
        width: open ? "400px" : "65px",
        height: open ? undefined : "65px", */
        transition: "width .3s",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {
        open ? <OpenDetectionDropdown hideMenu={hideMenu} /> : (
          <IconButton 
              onClick={handleToggleClick}
              sx={{
                padding: "15px",
                borderRadius: "10px",
                "& .MuiTouchRipple-root *, & .MuiTouchRipple-root, & .MuiTouchRipple-child":
                  {
                    borderRadius: "10px",
                  },
              }}
            >
              <LocalFireDepartment fontSize="large" sx={{ color: "#3E5AA9" }} />
          </IconButton>
        )
      }
    </Box>
  );
}

type OpenDetectionDropdownProps = {
  hideMenu: () => void;
};

const OpenDetectionDropdown = ({ hideMenu }: OpenDetectionDropdownProps) => {
	const { t } = useTranslation();
  const isMobile = useSelector(selectMobile);
  const [open, setOpen] = useState(false);
  const [selectedDetection, setSelectedDetection] = useState("satellite");

  const handleOpen = () => setOpen((prev) => !prev);

  const arrowIcon = open ? (
    <KeyboardArrowRightIcon sx={{ width: 20 }} />
  ) : (
    <KeyboardArrowLeftIcon sx={{ width: 20 }} />
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      color="white"
      marginTop="-37px"
    >
      <Box sx={{ 
        display: "flex", 
        justifyContent: "space-between",
        width: "100%"
      }}>
        <Button
          variant="text"
          color="inherit"
          startIcon={arrowIcon}
          sx={{ textTransform: "none" }}
          onClick={handleOpen}
        >
          {open ? t("dropdown-min") : t("dropdown-abrir")}
        </Button>
        <Button
          variant="text"
          color="inherit"
          startIcon={<CloseIcon sx={{ width: 20 }} />}
          sx={{ textTransform: "none" }}
          onClick={hideMenu}
        >
        </Button>
      </Box>
      <Box
        className={`detection-dropdown ${open ? "opened" : "closed"} ${
          selectedDetection === "ia" ? "ia" : ""
        }`}
      >
        <Box className="switcher-container">
          <DetectionSwitcher
            title={t("dropdown-satelital-titulo")}
            description={t("dropdown-satelital-descripcion")}
            selected={selectedDetection === "satellite"}
            onSelect={() => setSelectedDetection("satellite")}
            detectionType="satellite"
          />
          <DetectionSwitcher
            title={t("dropdown-ia-titulo")}
            description={t("dropdown-ia-descripcion")}
            selected={selectedDetection === "ia"}
            onSelect={() => setSelectedDetection("ia")}
            detectionType="ia"
          />
        </Box>
        {selectedDetection === "satellite" && (
          <SatelliteDetectionFilters visible={open} />
        )}
        {selectedDetection === "ia" && <IADetectionIntro />}
      </Box>
    </Box>
  );
};

export default DetectionDropdown;
