import { useEffect, useMemo } from 'react';
import { DeckProps } from '@deck.gl/core';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { useMap } from '@vis.gl/react-google-maps';

export const BaseMapDeckGL = ({layers}: DeckProps) => {

  const getCursor = ({ isDragging, isHovering }: { isDragging: boolean, isHovering: boolean }) => {
    const mapCursorDiv = document.querySelector('div[style*="cursor"]') as HTMLElement | null;
    let cursor;
   
    if (isDragging) {
      cursor = 'grabbing';
    } else if (isHovering) {
      cursor = 'pointer';
    } else {
      cursor = 'grab';
    }
    if (mapCursorDiv && cursor) {
      mapCursorDiv.style.cursor = cursor;
    }
    return cursor;
  };

  const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true, getCursor }), []);
  const map = useMap();

  const maxBounds = {
    north: 85,
    south: -85,
    west: -180,
    east: 180
  };

  map?.setOptions({
    minZoom: 2,
    restriction: {
      latLngBounds: maxBounds,
      strictBounds: true
    }
    // maxZoom: 6 // Optionally set minZoom if needed
  });

  useEffect(() => {
    deck.setMap(map);
    return () => deck.setMap(null);
  }, [deck, map]);

  useEffect(() => {
    deck.setProps({ layers });
  }, [deck, layers]);
  
  return null;
};

export default BaseMapDeckGL;