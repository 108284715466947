import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export function useDrawingManager(
  initialValue: google.maps.drawing.DrawingManager | null = null
) {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(initialValue);
  const [drawingMode, setDrawingMode] = useState<google.maps.drawing.OverlayType | null>(null);
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);
  const [infoWindows, setInfoWindows] = useState<google.maps.InfoWindow[]>([]);

  useEffect(() => {
    if (!map || !drawing) return;
    // https://developers.google.com/maps/documentation/javascript/reference/drawing
    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYLINE
        ]
      },
      polygonOptions: {
        editable: true,
        draggable: true
      },
      polylineOptions: {
        editable: true,
        //draggable: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        icons: [
          {
            icon: { path: google.maps.SymbolPath.CIRCLE, fillOpacity: 1, fillColor: '#FF0000', strokeOpacity: 1, scale: 2, strokeColor: '#FF0000' },
            offset: '0',
            repeat: '10px'
          }
        ]

      }
    });

    newDrawingManager.setDrawingMode(drawingMode);

    newDrawingManager.addListener('drawingmode_changed', (event: any) => {
      const currentMode = newDrawingManager.getDrawingMode();

      // Toggle logic: If the same mode is selected, turn it off
      if (currentMode === google.maps.drawing.OverlayType.POLYLINE) {
        if (drawingMode === google.maps.drawing.OverlayType.POLYLINE) {
          setDrawingMode(null); // Turn off drawing mode
          newDrawingManager.setDrawingMode(null); // Sync with the drawing manager
          polylines.forEach(polyline => polyline.setMap(null));
          infoWindows.forEach(infoWindow => infoWindow.close());
          setPolylines([]); // Clear the polylines array
          setInfoWindows([]); // Clear the InfoWindows array

        } else {
          setDrawingMode(google.maps.drawing.OverlayType.POLYLINE); // Turn on drawing mode
        }
      }
    });

    newDrawingManager.addListener('overlaycomplete', (event: any) => {
      if (event.type === 'polyline') {
        const path = event.overlay.getPath().getArray();
        const isClosed = path[0].equals(path[path.length - 1]);

        // Calculate the length of the drawn line
        const lengthInMeters = google.maps.geometry.spherical.computeLength(path);
        const lengthInKm = lengthInMeters / 1000; // Convert to kilometers
        const value = lengthInMeters >= 1000 ? `${lengthInKm.toFixed(2)} km` : `${lengthInMeters.toFixed(2)} m`;

        const lastPoint = path[path.length - 1];
        let infoContent = `<b>Distance:</b> ${value}`;

        if (isClosed) {
          // Convert polyline to polygon for area calculation
          const polygonPath: google.maps.LatLng[] = path.map((latLng: google.maps.LatLng) => new google.maps.LatLng(latLng.lat(), latLng.lng()));
          // Calculate the area using google.maps.geometry.spherical.computeArea
          const areaInSqMeters = google.maps.geometry.spherical.computeArea(polygonPath);
          if (areaInSqMeters) {
            const areaInSqKm = areaInSqMeters / 1000000; // Convert to square kilometers
            const areaValue = areaInSqMeters >= 1000000 ? `${areaInSqKm.toFixed(2)} km²` : `${areaInSqMeters.toFixed(2)} m²`;
            infoContent += `<br><b>Area:</b> ${areaValue}`;
          }
        }

        var infowindow = new google.maps.InfoWindow({
          content: infoContent
        });
        infowindow.setPosition(lastPoint);
        infowindow.open({ map });

        // Save the polyline and infowindow references
        setPolylines(prevPolylines => [...prevPolylines, event.overlay]);
        setInfoWindows(prevInfoWindows => [...prevInfoWindows, infowindow]);
      }
    });

    setDrawingManager(newDrawingManager);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDrawingMode(null);
        newDrawingManager.setDrawingMode(null); // Stop drawing
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      newDrawingManager.setMap(null);
    };
  }, [drawing, map, drawingMode, polylines, infoWindows]);

  const toggleDrawingMode = () => {
    if (drawingMode === 'polyline') {
      setDrawingMode(null); // Toggle off
    } else {
      setDrawingMode(google.maps.drawing.OverlayType.POLYLINE); // Toggle on
    }
  };

  return { drawingManager, toggleDrawingMode };
}