import { useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18nextConf";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { actions as modalActions } from "../../../redux/slices/modalsSlice";
import { actions as sidebarActions } from "../../../redux/slices/sidebarSlice";
import { InfoOutlined } from "@mui/icons-material";
import SatelliteFilters from "./SatelliteFilters";
import { SatelliteDetectionFiltersProps } from "../../../types/components/dropdown/SatelliteDetectionFilters";

const SatelliteDetectionFilters: React.FC<SatelliteDetectionFiltersProps> = ({
  visible,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sub = useSelector((state: any) => state.user?.userData?.sub);

  useEffect(() => {
    dispatch(sidebarActions.setMode('satellite'));
  }, [])

  return (
    <Box className="satellite-detection">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className="title">
          {t("filtro-focos-titulo-menu")} 
        </Typography>
        {i18n.language.includes("es") && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              ReactGA.event({ category: "Info Tipo de foco", action: sub });
              dispatch(modalActions.toggleInfoModal());
            }}
          >
            <InfoOutlined />
          </IconButton>
        )}
      </Box>
      <Box
        className="filters-container"
        sx={{
          visibility: visible ? "visible" : "hidden",
        }}
      >
        <SatelliteFilters />
      </Box>
    </Box>
  );
};

export default SatelliteDetectionFilters;
