import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import bugsnag from '@bugsnag/js';
import { fetchWildfires } from "../../api/wildfires/getWildfires";
import { createWildfireReview } from "../../api/wildfires/createWildfireReview";
import fetchUserReviews from "../../api/wildfires/getUserReviews";
import { getByDate } from "../../api/wildfires/getByDate";
import { MapWildfire, WildfireReview, WildfiresState } from "./types/wildfireTypes";
import { RootState } from "../store";

const initialState: WildfiresState = {
	status: "idle", // status: 'idle' | 'loading' | 'succeeded' | 'failed',
	error: null, // error: string | null
	source: null, // source: string | null
	wildfires: [],
	rays: [],
	reviews: {}
};

export const getWildfiresByDate = createAsyncThunk(
	"wildfires/getWildfiresByDate",
	async (date, thunkAPI) => {
		//const wildfires = await lastTenMinutes();
		const [wildfires, SRWildfires] = await Promise.all([
			getByDate(date),
			getByDate(date, 'getPublicSRWildfireByDate')
		]);
		return wildfires.concat(SRWildfires);
	}
);

export const getLastTenMinutes = createAsyncThunk(
	"wildfires/getLastTenMinutes",
	async (args, thunkAPI) => {
		//const wildfires = await lastTenMinutes();
		const [wildfires, SRWildfires] = await Promise.all([
			fetchWildfires(20),
			fetchWildfires(20, 'getPublicSRWildfireByDate')
		]);
		return wildfires.concat(SRWildfires);
	}
);

export const getLastHour = createAsyncThunk(
	"wildfires/getLastHour",
	async (args, thunkAPI) => {
		const now = new Date();
		const minutes = now.getMinutes();
		const minutesSinceLastHour = 60 + minutes + 1;
		const [wildfires, SRWildfires] = await Promise.all([
			fetchWildfires(61),
			fetchWildfires(minutesSinceLastHour, 'getPublicSRWildfireByDate')
		]);
		return wildfires.concat(SRWildfires);
	}
);

export const getLastThreeHours = createAsyncThunk(
	"wildfires/getLastThreeHours",
	async (args, thynkAPI) => {
		const now = new Date();
		const minutes = now.getMinutes();
		const minutesSinceLastHour = 180 + minutes + 1;
		const [wildfires, SRWildfires] = await Promise.all([
			fetchWildfires(181),
			fetchWildfires(minutesSinceLastHour, 'getPublicSRWildfireByDate')
		]);
		return wildfires.concat(SRWildfires);
	}
);

export const reviewWildfire = createAsyncThunk(
	"wildfires/reviewWildfire",
	async (data: WildfireReview, thynkAPI) => {
		const status = await createWildfireReview(data);
		return status
	}
);

export const retrieveUserReviews = createAsyncThunk(
	"wildfires/retrieveUserReviews",
	async (args, thunkAPI) => {
		return await fetchUserReviews();
	}
);


const slice = createSlice({
	name: "wildfires",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getWildfiresByDate.fulfilled, (state: WildfiresState, action: PayloadAction<MapWildfire[]>) => {
				state.wildfires = action.payload;
				state.status = "succeded";
				state.source = "getWildfiresByDate";
			})
			.addCase(getLastTenMinutes.fulfilled, (state: WildfiresState, action: PayloadAction<MapWildfire[]>) => {
				state.wildfires = action.payload;
				state.status = "succeded";
				state.source = "getLastTenMinutes";
			})
			.addCase(getLastHour.fulfilled, (state: WildfiresState, action: PayloadAction<MapWildfire[]>) => {
				state.wildfires = action.payload;
				state.status = "succeded";
				state.source = "getLastHour";
			})
			.addCase(getLastThreeHours.fulfilled, (state: WildfiresState, action: PayloadAction<MapWildfire[]>) => {
				state.wildfires = action.payload;
				state.status = "succeded";
				state.source = "getLastThreeHours";
			})
			.addCase(reviewWildfire.fulfilled, (state: WildfiresState, action) => {
				const newReview = action.payload.data.createWildfiresReview;
				state.reviews[newReview.wildfire_id] = newReview;
				state.status = "succeded";
			})
			.addCase(retrieveUserReviews.fulfilled, (state: WildfiresState, action: PayloadAction<{ [key: string]: WildfireReview }>) => {
				state.reviews = action.payload;
			})
			.addMatcher(
				(action) => action.type.endsWith("pending") && action.type.startsWith("wildfires") && !action.type.includes("retrieveUserReviews"),
				(state: WildfiresState, action) => {
					state.status = "loading";
				}
			)
			.addMatcher(
				(action) => action.type.endsWith("rejected") && action.type.startsWith("wildfires"),
				(state: WildfiresState, action) => {
					bugsnag.notify(new Error(action.error.message));
					state.status = "failed";
					state.error = action.error.message;
				}
			);
	},
});

export const actions = {
	...slice.actions,
	getWildfiresByDate,
	getLastHour,
	getLastTenMinutes,
	getLastThreeHours,
	reviewWildfire,
	retrieveUserReviews
};

export const selectWildfires = (state: RootState) => state.wildfires.wildfires;
export const selectRays = (state: RootState) => state.wildfires.rays;
export const selectStatus = (state: RootState) => state.wildfires.status;
export const selectSource = (state: RootState) => state.wildfires.source;
export const selectReviews = (state: RootState) => state.wildfires.reviews;

export default slice.reducer;