/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPolygon = /* GraphQL */ `query GetPolygon($id: String!) {
  getPolygon(id: $id) {
    id
    name
    description
    coords
    detectRays
    detectWildfires
    detectDeforestation
    detectAI
    lastModificationDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPolygonQueryVariables,
  APITypes.GetPolygonQuery
>;
export const listUserCameras = /* GraphQL */ `query ListUserCameras(
  $filter: TableCamerasFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      cameraId
      cameraUrl
      cgiPort
      ip
      user
      password
      x
      y
      elevation
      sensorHeight
      sensorWidth
      movementType
      lastDetectionDate
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCamerasQueryVariables,
  APITypes.ListUserCamerasQuery
>;
export const listUserCameraEvents = /* GraphQL */ `query ListUserCameraEvents(
  $cameraId: String!
  $filter: TableCameraEventsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCameraEvents(
    cameraId: $cameraId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date_id
      bbox
      classNumber
      confidence
      confirmWildfire
      hasBeenDetectedByHuman
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCameraEventsQueryVariables,
  APITypes.ListUserCameraEventsQuery
>;
export const listPolygons = /* GraphQL */ `query ListPolygons(
  $filter: TablePolygonFilterInput
  $limit: Int
  $nextToken: String
) {
  listPolygons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      coords
      detectRays
      detectWildfires
      detectDeforestation
      detectAI
      lastModificationDate
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPolygonsQueryVariables,
  APITypes.ListPolygonsQuery
>;
export const getUserWildfire = /* GraphQL */ `query GetUserWildfire($polygonId: String!, $date_id: String!) {
  getUserWildfire(polygonId: $polygonId, date_id: $date_id) {
    date_id
    cat
    conf
    sat
    x
    y
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserWildfireQueryVariables,
  APITypes.GetUserWildfireQuery
>;
export const listUserWildfires = /* GraphQL */ `query ListUserWildfires(
  $polygonId: String!
  $filter: TableUserWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserWildfires(
    polygonId: $polygonId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date_id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserWildfiresQueryVariables,
  APITypes.ListUserWildfiresQuery
>;
export const getUserLightning = /* GraphQL */ `query GetUserLightning($polygonId: String!, $date_id: String!) {
  getUserLightning(polygonId: $polygonId, date_id: $date_id) {
    date_id
    cat
    conf
    sat
    x
    y
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserLightningQueryVariables,
  APITypes.GetUserLightningQuery
>;
export const listUserLightnings = /* GraphQL */ `query ListUserLightnings(
  $polygonId: String!
  $filter: TableUserWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserLightnings(
    polygonId: $polygonId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date_id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserLightningsQueryVariables,
  APITypes.ListUserLightningsQuery
>;
export const getUserDeforestation = /* GraphQL */ `query GetUserDeforestation($polygonId: String!, $date_id: String!) {
  getUserDeforestation(polygonId: $polygonId, date_id: $date_id) {
    date_id
    cat
    conf
    sat
    x
    y
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserDeforestationQueryVariables,
  APITypes.GetUserDeforestationQuery
>;
export const listUserDeforestations = /* GraphQL */ `query ListUserDeforestations(
  $polygonId: String!
  $filter: TableUserWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserDeforestations(
    polygonId: $polygonId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date_id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserDeforestationsQueryVariables,
  APITypes.ListUserDeforestationsQuery
>;
export const listUserWildfiresKML = /* GraphQL */ `query ListUserWildfiresKML($polygonId: String!) {
  listUserWildfiresKML(polygonId: $polygonId) {
    kml
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserWildfiresKMLQueryVariables,
  APITypes.ListUserWildfiresKMLQuery
>;
export const getNotificationEndpoint = /* GraphQL */ `query GetNotificationEndpoint($endpointId: String!) {
  getNotificationEndpoint(endpointId: $endpointId) {
    endpointId
    endpointName
    endpoint
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationEndpointQueryVariables,
  APITypes.GetNotificationEndpointQuery
>;
export const listNotificationEndpoints = /* GraphQL */ `query ListNotificationEndpoints(
  $filter: TableNotificationEndpointFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationEndpoints(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      endpointId
      endpointName
      endpoint
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationEndpointsQueryVariables,
  APITypes.ListNotificationEndpointsQuery
>;
export const getUserConfig = /* GraphQL */ `query GetUserConfig {
  getUserConfig {
    name
    email
    country
    phone
    organizationName
    organizationType
    freeTrial
    language
    notifyByEmail
    notifyByPush
    notifyByPhone
    userType
    userTypeTill
    defaultLayer
    notifyMinuteGap
    typeOfAlert
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConfigQueryVariables,
  APITypes.GetUserConfigQuery
>;
export const listPublicWildfires = /* GraphQL */ `query ListPublicWildfires(
  $filter: PublicWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  listPublicWildfires(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      date
      id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPublicWildfiresQueryVariables,
  APITypes.ListPublicWildfiresQuery
>;
export const getPublicWildfireByDate = /* GraphQL */ `query GetPublicWildfireByDate(
  $date: String!
  $idGtThan: String
  $filter: PublicWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  getPublicWildfireByDate(
    date: $date
    idGtThan: $idGtThan
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date
      id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicWildfireByDateQueryVariables,
  APITypes.GetPublicWildfireByDateQuery
>;
export const getPublicSRWildfireByDate = /* GraphQL */ `query GetPublicSRWildfireByDate(
  $date: String!
  $idGtThan: String
  $filter: PublicWildfiresFilterInput
  $limit: Int
  $nextToken: String
) {
  getPublicSRWildfireByDate(
    date: $date
    idGtThan: $idGtThan
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      date
      id
      cat
      conf
      sat
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicSRWildfireByDateQueryVariables,
  APITypes.GetPublicSRWildfireByDateQuery
>;
export const getWildfireGroupData = /* GraphQL */ `query GetWildfireGroupData($date: String!, $id: String!) {
  getWildfireGroupData(date: $date, id: $id) {
    startDate
    lastDate
    lastDateComplete
    clusterId
    x
    y
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWildfireGroupDataQueryVariables,
  APITypes.GetWildfireGroupDataQuery
>;
export const getWildfireReviewByUser = /* GraphQL */ `query GetWildfireReviewByUser($user_id: String!, $wildfire_id: String!) {
  getWildfireReviewByUser(user_id: $user_id, wildfire_id: $wildfire_id) {
    user_id
    wildfire_id
    wildfire_date
    result
    origin
    review_date
    user_description
    source
    cathegory
    x
    y
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWildfireReviewByUserQueryVariables,
  APITypes.GetWildfireReviewByUserQuery
>;
export const getUserReviews = /* GraphQL */ `query GetUserReviews(
  $filter: TableWildfiresReviewsFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      user_id
      wildfire_id
      wildfire_date
      result
      origin
      review_date
      user_description
      source
      cathegory
      x
      y
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserReviewsQueryVariables,
  APITypes.GetUserReviewsQuery
>;
