import { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Box, Divider, Slide, styled, TextField, Typography, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import MuiFab from "@mui/material/Fab";
import area from "@turf/area";
import { Done, EditOutlined, LayersOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "../../StyledComponents/Dialog";
import { Button } from "../../StyledComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import LeafletMap from "../../mapa/LeafletMap";
import ConfirmationDialog from "../../modals/ConfirmationDialog";
import { useLocation } from "react-router-dom";
import { actions as mapActions } from "../../../redux/slices/mapSlice";

const MAX_DEFORESTATION_AREA = 10000;

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

export default function EditarZona({ open, close }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [featureCoords, setFeatureCoords] = useState();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [datos, setDatos] = useState({
		nombreZona: "",
		description: "",
		coords: "",
		detectRays: false,
		detectWildfires: false,
		detectDeforestation: false,
		detectAI: false
	});
	const [error, setError] = useState(false);
	const [estimatedArea, setEstimatedArea] = useState(0);
	const [polygon, setPolygon] = useState();

	let query = useQuery();
	const id = query.get("id");
	const polygons = useSelector((state) => state.polygons.polygons);

	useEffect(() => {
		//Se consigue el poligono con el ID del path
		if (polygons.length > 0) {
			polygons.forEach((poly) => {
				if (poly.id === id) {
					setPolygon(poly);
				}

			});
		}
	}, [polygons, open, id]);

	useEffect(() => {
		if (!polygon) return;
		setDatos({
			nombreZona: polygon.name,
			description: polygon.description,
			coords: polygon.coords,
			detectRays: polygon.detectRays,
			detectWildfires: polygon.detectWildfires,
			detectDeforestation: polygon.detectDeforestation,
			detectAI: polygon.detectAI
		});
	}, [polygon]);

	const [editing, setEditing] = useState(false);
	const [drawControl, setDrawControl] = useState();

	const editDraw = () => {
		drawControl._toolbars.edit._modes.edit.handler.enable();
		setEditing(true);
	};

	const saveEdit = () => {
		drawControl._toolbars.edit._modes.edit.handler.save();
		drawControl._toolbars.edit._modes.edit.handler.disable();
		setEditing(false);
	};

	const changeLayer = () => {
		dispatch(mapActions.toggleLayerLeaflet());
	};

	const handleInputChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handleTypeOfAlertsChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		let coordinates;
		if (featureCoords) {
			coordinates = featureCoords.map((item) => item.map((elem) => [elem.lat, elem.lng]));
			coordinates = coordinates[0];
		} else coordinates = datos.coords;

		close();
		const sendData = {
			id: polygon.id,
			name: datos.nombreZona,
			description: datos.description,
			coords: JSON.stringify(coordinates),
			detectRays: datos.detectRays,
			detectWildfires: datos.detectWildfires,
			detectDeforestation: datos.detectDeforestation,
			detectAI: datos.detectAI
		};
		dispatch(polygonActions.updatePolygon(sendData));
	};

	const handleDelete = () => {
		handleClose();
		setDeleteDialogOpen(true);
	};

	const handleClose = async () => {
		setEditing(false);
		close();
	};

	const calculateArea = (polygon) => {
		if (!polygon || !polygon.coordinates) return 0;

		var polygonAreaInKM2 = area(polygon) / 1000000;
		return polygonAreaInKM2;
	}

	const convertToGeoJSONPolygon = (coords) => {
		let convertedCoords = coords;
		if (convertedCoords.length === 3) {
			convertedCoords = [...convertedCoords, convertedCoords[0]];
		}
		const geoJsonPolygon = {
			type: "Polygon",
			coordinates: [convertedCoords]
		};
		return geoJsonPolygon;
	}

	useEffect(() => {
		let polygon;
		if (!featureCoords && datos.coords) {
			polygon = convertToGeoJSONPolygon(datos.coords);
		} else if (featureCoords) {
			polygon = convertToGeoJSONPolygon(featureCoords);
		}
		const result = calculateArea(polygon);

		setEstimatedArea(result);

		if (result && (datos.detectDeforestation || datos.detectAI)) {
			if (result > MAX_DEFORESTATION_AREA) {
				setError(true);
			}
		} else {
			setError(false);
		}
	}, [featureCoords, datos]);

	return (
		<>
			<ConfirmationDialog
				zone={polygon}
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				scroll="body"
				TransitionComponent={Transition}
			>
				<form onSubmit={handleSubmit}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<DialogTitle>{t("titulo-editar-zona")}</DialogTitle>{" "}
						<Button variant="outlined" color="error" onClick={handleDelete}>
							{t("boton-eliminar-zona")}
						</Button>
					</Box>
					<Divider />
					<DialogContent>
						<Typography
							variant="span"
							color="#707176"
							fontSize="0.9rem"
							sx={{ marginTop: "20px", marginBottom: "10px" }}
						>
							{t('typography-editar-zona')}
						</Typography>
						<Box
							display="flex"
							flexDirection="column"
							gap="10px"
						>
							<TextField
								name="nombreZona"
								label={t("nombre-de-la-zona")}
								variant="standard"
								required
								value={datos.nombreZona}
								onChange={handleInputChange}
							/>
							<TextField
								name="description"
								label={t("descripcion-de-la-zona")}
								variant="standard"
								value={datos.description || ""}
								onChange={handleInputChange}
							/>
						</Box>
						<Box display="flex" flexDirection="column" gap="5px" marginTop="20px" marginBottom="10px">
							<Typography variant="span" color="#707176" fontSize="0.9rem">
								{t('typography-editar-focos-rayos')}
							</Typography>
							<Box
								display="flex"
								justifyContent="space-between"
								//gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
								width="100%"
								alignSelf="center"
							>
								<FormControlLabel
									name="detectWildfires"
									control={
										<Checkbox
											style={{
												color: "#B71C1C",
											}}
											edge="start"
											checked={!!datos.detectWildfires}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-calor')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>
								<FormControlLabel
									name="detectRays"
									control={
										<Checkbox
											style={{
												color: "#4264FB",
											}}
											edge="start"
											checked={!!datos.detectRays}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-rayos')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>
								<Tooltip
									PopperProps={{
										disablePortal: true,
									}}
									open={error}
									disableFocusListener
									disableHoverListener
									disableTouchListener
									title={t("error-poligono-deforestacion", {MAX_VALUE: MAX_DEFORESTATION_AREA})}
									arrow
								>
									<FormControlLabel
										name="detectDeforestation"
										control={
											<Checkbox
												style={{
													color: "#039048",
												}}
												edge="start"
												checked={!!datos.detectDeforestation}
												disableRipple
												onChange={(e) =>
													handleTypeOfAlertsChange(e)
												}
											/>
										}
										label={t('focos-de-deforestacion')}
										labelPlacement="end"
										sx={{ margin: 0 }}
									/>
								</Tooltip>
								<FormControlLabel
										name="detectAI"
										control={
											<Checkbox
												style={{
													color: "#996DD7",
												}}
												edge="start"
												checked={!!datos.detectAI}
												disableRipple
												onChange={(e) =>
													handleTypeOfAlertsChange(e)
												}
											/>
										}
										label={t('focos-de-ia')}
										labelPlacement="end"
										sx={{ margin: 0 }}
									/>
							</Box>
						</Box>
						<Box
							width="100%"
							position="relative"
							sx={{
								aspectRatio: "16 / 9",
								borderRadius: "10px",
								overflow: "hidden",
							}}
						>
							<LeafletMap
								featureCoords={datos.coords}
								setFeatureCoords={setFeatureCoords}
								edit={true}
								onEdit={() => setEditing(false)}
								setDrawControl={setDrawControl}
							/>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="flex-end"
								gap="10px"
								position="absolute"
								zIndex="9"
								sx={{
									right: "10px",
									bottom: "15px",
									"& .MuiSvgIcon-root": { animation: "iconChange .3s" },
								}}
							>
								<Fab size="small" onClick={changeLayer}>
									<LayersOutlined />
								</Fab>
								<Fab size="medium" onClick={editing ? saveEdit : editDraw}>
									{editing ? <Done /> : <EditOutlined />}
								</Fab>
							</Box>
						</Box>
						<Box
							display="flex"
							flexDirection="row"
							gap="10px"
							sx={{ justifyContent: "space-between", marginTop: "20px" }}
						>
							<Box>
								<Typography color="#707176" fontSize=".9rem">{t("superficie-estimada")}: {estimatedArea ? Math.floor(estimatedArea * 100) / 100 : 0} km2</Typography>
							</Box>
							<Box>
								<Button variant="text" onClick={handleClose}>
									{t('boton-cancelar')}
								</Button>

								{editing === false && !error && (
									<Button variant="outlined" type="submit">
										{t("boton-guardar-zona")}
									</Button>
								)}
							</Box>
						</Box>
					</DialogContent>
				</form>
			</Dialog>
		</>
	);
}

const Fab = styled(MuiFab)(({ theme }) => ({
	boxShadow: "none",
	borderRadius: "12px",
	backgroundColor: theme.palette.primary.light,
	"&.MuiFab-sizeMedium": {
		height: "46px",
		width: "46px",
		"& .MuiSvgIcon-root": {
			height: "25px",
			width: "25px",
		},
	},
	"&.MuiFab-sizeSmall": {
		height: "36px",
		width: "36px",
		"& .MuiSvgIcon-root": {
			height: "20px",
			width: "20px",
		},
	},
}));
