import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CustomMeasureControl } from './CustomMeasureControl';
import { CustomFullScreenControl } from './CustomFullScreenControl';
import { CustomGeolocationControl } from './CustomGeolocationControl';

type CustomZoomControlProps = {
  controlPosition: ControlPosition;
  onUserLocationChange: (location: google.maps.LatLngLiteral) => void;
};

export const CustomControl = ({ controlPosition, onUserLocationChange }: CustomZoomControlProps) => {

  const map = useMap();

  const handleZoomIn = () => {
    const mapZoom: number = map?.getZoom() || 1;
    if (mapZoom < 18) {
      map?.setZoom(mapZoom + 1);
    };
  };

  const handleZoomOut = () => {
    const mapZoom: number = map?.getZoom() || 1;
    if (mapZoom > 1)
      map?.setZoom(mapZoom - 1);
  };

  return (
    <MapControl position={controlPosition}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f5f5f5',
          padding: '4px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          width: '40px',
          margin: '20px'
        }}
      >
        <Button
          onClick={handleZoomIn}
          sx={{
            minWidth: '20px',
            minHeight: '20px',
            padding: 0,
            color: '#000',
          }}
        >
          <AddIcon />
        </Button>
        <Button
          onClick={handleZoomOut}
          sx={{
            minWidth: '20px',
            minHeight: '20px',
            padding: 0,
            color: '#000',
          }}
        >
          <RemoveIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f5f5f5',
          padding: '4px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          width: '40px',
          margin: '20px'
        }}
      >
        <CustomMeasureControl />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f5f5f5',
          padding: '4px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          width: '40px',
          margin: '20px'
        }}
      >
        <CustomFullScreenControl />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f5f5f5',
          padding: '4px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          width: '40px',
          margin: '20px'
        }}
      >
        <CustomGeolocationControl
          changeLocation={onUserLocationChange}
        />
      </Box>
    </MapControl>
  );
};
