import React from 'react';
import { ZoomControl, useMap } from 'react-leaflet';

import { FullscreenControl } from 'react-leaflet-fullscreen';
import BaseMapWindy, { WindyMapLayer } from './BaseMapWindy';
import { CameraMarkers } from './CameraMarkers';
import { PointerMarker } from './PointerMarker';
import { WildfireMarkers } from './WildfireMarkers';
import { WildfirePolygon } from './WildfirePolygon';

import RulerControl from 'react-leaflet-ruler';
import { MapProps } from '../MapTypes';

import { ControlPosition } from 'leaflet';
import { useSelector } from 'react-redux';
import { selectMobile } from '../../../redux/slices/sidebarSlice';

function CenterController(props: { center: number[] }) {
  const map = useMap();

  React.useEffect(() => {
    const center = props.center;
    if (center.length > 2 && center[0] && center[1])
      map.flyTo([center[0], center[1]], center[2] + 2);
  }, [props.center, map]);

  return null;
}

export default function WindyMap(props: MapProps) {

  const mobile = useSelector(selectMobile);
  const controlPosition: ControlPosition = (mobile ? 'topleft' : 'topright');
  const windyLayer = props.layer.substring("windy-".length);

  return (
    <>
      <BaseMapWindy
        center={props.defaultCenter}
        zoom={props.defaultZoom}
        style={{ width: '100%', height: '100%' }}
        windyLayer={windyLayer as WindyMapLayer}
      >
        <WildfireMarkers
          wildfireType={props.wildfireType} wildfires={props.wildfires} onClickWildfire={props.onClickWildfire} />
        <WildfirePolygon
          polygon={props.polygon} onClickPolygon={props.onClickPoly} />
        <CameraMarkers
          cameras={props.cameras} onClickCamera={props.onClickCamera} />
        <PointerMarker point={props.coordsMarker} />
        <CenterController center={props.center} />
        <ZoomControl position={controlPosition} />
        <FullscreenControl position={controlPosition} />
        <RulerControl />
      </BaseMapWindy>
    </>
  )
}