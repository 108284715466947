import { IconLayer } from '@deck.gl/layers';
import { PickingInfo } from '@deck.gl/core';
import { MjolnirEvent } from 'mjolnir.js';
import imageCamera from "../../../../assets/Camara.png";
import { Camera } from '../../../../graphql/API';

export function cameraMarkers(cameras: Camera[], onClickCamera: (id: string) => void): IconLayer {

  const onClick = (pickingInfo: PickingInfo<Camera>, event: MjolnirEvent) => {
    onClickCamera(pickingInfo.object?.cameraId || "");
  }

  const layer: IconLayer<Camera> = new IconLayer<Camera>({
    id: 'CameraMarkersLayer',
    data: cameras,
    pickable: true,
    getIcon: (d: Camera) => 'camera',
    iconAtlas: imageCamera,
    iconMapping: {
      camera: {
        x: 0,
        y: 0,
        width: 512,
        height: 512,
        mask: false
      }
    },
    sizeScale: 50,
    getPosition: (d: Camera) => [d.x || 0, d.y || 0],
    onClick: onClick
  });

  return layer;
}