
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import i18n from "../../../i18nextConf";
import { Button } from "../../StyledComponents/Button";
import { getZone } from "../../../api/wildfires/utils/getZone";
import { selectUserData } from "../../../redux/slices/userSlice";
import { actions as wildfireActions } from "../../../redux/slices/wildfireSlice";
import ConfirmWildfire from "../../desktop/Dialogs/ConfirmWildfire";
import { MapWildfire, WildfireReview } from "../../../redux/slices/types/wildfireTypes";

export function WildfirePopup(props: { wildfire: MapWildfire, reviewed: boolean, wildfireType: 'user' | 'public', loggedIn: boolean }) {
  const dispatch = useDispatch();
  const wildfire = props.wildfire;
  const wildfireType = props.wildfireType;
  const loggedIn = props.loggedIn;
  const lat = wildfire.y.toFixed(4), long = wildfire.x.toFixed(4);
  const [zone, setZone] = useState<string>("");
  const [confirmWildfireOpen, setConfirmWildfireOpen] = useState<boolean>(false);
  const [selectedWildfire, setSelectedWildfire] = useState<WildfireReview | null>(null);
  const userData = useSelector(selectUserData).userData
  const isDeforestation = wildfire.satellite === 'gfw-integrated-alerts';

  useEffect(() => {
    if (selectedWildfire !== null) {
      setConfirmWildfireOpen(true);
    } else {
      setConfirmWildfireOpen(false);
    }
  }, [selectedWildfire]);

  const openConfirmWildfireDialog = (data: WildfireReview) => {
    setSelectedWildfire(data);
  };
  const closeReviewDialog = () => {
    setSelectedWildfire(null);
  };
  const handleConfirmSubmit = (date: Date) => {
    if (!selectedWildfire) return

    const data: WildfireReview = {
      ...selectedWildfire,
      wildfire_date: date // date.toISOString().slice(0, 16)
    };
    dispatch(wildfireActions.reviewWildfire(data));
    setSelectedWildfire(null);
  };

  useEffect(() => {
    setZone("...");
    (async () => {
      const zone = await getZone([wildfire.x, wildfire.y]);
      setZone(zone);
    })();
  }, [wildfire]);

  const onValidationClick = async (answer: string) => {
    const { id, x, y, satellite, cathegoryNum } = wildfire;
    const wildfire_date = wildfireType === 'user' ? new Date(id.split('_')[0]) : new Date(`${id.split('+')[0]}+${id.split('+')[1]}`);
    const data: WildfireReview = {
      wildfire_id: id,
      result: answer,
      origin: wildfireType,
      wildfire_date: wildfire_date,
      user_description: `${userData.name} (${userData.email})`,
      source: satellite,
      cathegory: `${cathegoryNum}`,
      x: x,
      y: y
    };
    if (answer === 'Y') {
      openConfirmWildfireDialog(data);
    } else {
      dispatch(wildfireActions.reviewWildfire({
        ...data,
        wildfire_date: null
      }));
    }
  }

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>{i18n.t('popup-titulo')}</h3>
      <Typography style={{ textAlign: 'left' }}><b>{i18n.t('tipo-de-foco')}: </b>{wildfire.cathegory}</Typography>
      <Typography style={{ textAlign: 'left' }}><b>{i18n.t('hora-campo-foco')}</b>{wildfire.date}</Typography>
      <Typography style={{ textAlign: 'left' }}><b>{i18n.t('coords-campo-foco')}</b>{lat}, {long}</Typography>
      <Typography style={{ textAlign: 'left' }}><b>{i18n.t('zona-campo-foco')}</b>{zone}</Typography>
      <Typography style={{ textAlign: 'left' }}><b>{i18n.t('satelite-campo-foco')}</b> {isDeforestation ? 'GFW Alerts' : wildfire.satellite}</Typography>
      {
        (loggedIn && !props.reviewed) && (
          <Box marginTop="5px">
            <Typography style={{ fontSize: "14px", display: 'table', margin: '0 auto' }}>{isDeforestation ? i18n.t("confirmar-deforestacion") : i18n.t("confirmar-incendio")}</Typography>
            <Box display="flex" justifyContent="center" gap="10px">
              <Button onClick={(e) => onValidationClick("Y")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">{i18n.t("confirmar-si")}</Button>
              <Button onClick={(e) => onValidationClick("N")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">{i18n.t("confirmar-no")}</Button>
              <Button onClick={(e) => onValidationClick("D")} style={{ padding: "0.75rem", minWidth: "0", width: "4rem" }} variant="outlined">{i18n.t("confirmar-nose")}</Button>
            </Box>
          </Box>
        )
      }
      <ConfirmWildfire
        open={confirmWildfireOpen}
        close={closeReviewDialog}
        initialDate={selectedWildfire?.wildfire_date}
        submit={handleConfirmSubmit}
      />
    </div>
  )
}